

















































import { Component, Mixins } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import AppFormWrapper from "@/layouts/AppFormWrapper.vue";
import FileManagerService from "@/services/FileManagerService";
import TokenService from "@/services/TokenService";

@Component({
  components: {
    AppFormWrapper,
  },
})
export default class NdaFormView extends Mixins(RulesMixin) {
  isSubmitting = false;
  submitted = false;
  isTokenValid: boolean | null = null;
  nda: File | null = null;

  async handleSubmit(): Promise<void> {
    try {
      this.isSubmitting = true;
      const response = await FileManagerService.uploadOwnNda(
        this.nda as File,
        this.token
      );
      this.submitted = response.successful;
    } catch (e) {
      this.$snackbarError(this.$t("apiErrors.unableToSave"));
    } finally {
      this.isSubmitting = false;
    }
  }

  get token() {
    return Array.isArray(this.$route.query.token)
      ? this.$route.query.token[0] || ""
      : this.$route.query.token;
  }

  async created() {
    try {
      const response = await TokenService.isValid(this.token);
      this.isTokenValid = !response.expired;
    } catch (e) {
      this.isTokenValid = false;
    }
  }
}
